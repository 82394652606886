<template>
  <div class="container-fluid common-error-404-outer">
    <div
      class="
        d-flex
        justify-content-center
        align-items-center
        common-error-404-inner
      "
    >
      <div class="common-error-404-box">
        <div class="error-404-baner">
          <div class="text-center">
            <img
              src="/assets/images/404.png"
              class="img-fluid"
              alt="404" style="height: 300px;"
            />
          </div>
        </div>
        <div class="heading">Page Not Found</div>
        <div class="subheading">
          <div>We're sorry, the page you requested could not be found</div>
          <div>Please go back to the homepage</div>
        </div>
        <div class="back-to-home-btn text-center">
          <button type="submit" @click="redirectfn" class="custom-btn-45" >
            Home Page
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirectfn() {
      this.$router.push("/dashboard");
    },
  }
};
</script>

<style scoped>
.common-error-404-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 0;
}
.common-error-404-outer .common-error-404-inner {
  height: 100vh;
}
.common-error-404-box .error-404-baner {
  padding-bottom: 47px;
  text-align: center;
  padding-top: 47px;
}
.common-error-404-box .heading {
  font-family: 'AcuminPro-SemiBold';
  font-size: 30px;
  letter-spacing: 0px;
  color: #2a2a2a;
  line-height: 33px;
  text-align: center;
  padding-bottom: 30px;
}
.common-error-404-box .subheading {
  font-family: 'AcuminPro-Regular';
  font-size: 16px;
  letter-spacing: 0px;
  color: #2a2a2a;
  text-align: center;
  line-height: 25px;
  margin-bottom: 20px;
}
.common-error-404-box .back-to-home-btn {
  width: 322px;
  margin: 0 auto;
}
.custom-btn-45{
  width: 200px;
    background: #5266E5 0% 0% no-repeat padding-box;
    border-color: #5266E5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #FFFFFF;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    padding: 9px 11px;
    font-family: 'AcuminPro-Regular';
    margin-top: 4px;
}
</style>
